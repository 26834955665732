import React from "react";
import Image from "next/image";

const Section = ({
                   children,
                   bgImage,
                   lighten = false,
                   classes = "",
                   id = "",
                 }: { children: React.ReactNode, bgImage?: string, lighten?: boolean, classes?: string; id?: string },
) => {
  return (
    <section
      id={id}
      className={`relative flex ${lighten ? "bg-light-dark-brand" : "bg-dark-brand"} ${classes}`}
    >
      {bgImage ? (
        <div className="absolute inset-0">
          <Image
            fill={true}
            className="object-center object-cover pointer-events-none bg-no-repeat bg-center bg-cover z-n1"
            src={bgImage}
            alt={""}
            priority={true}
          />
        </div>
      ) : null}
      <div className="relative z-10 container mx-auto flex flex-col content-center items-center py-14">
        {children}
      </div>
    </section>
  );
};
export default Section;
