"use client";
import Section from "@/components/layout/Section";

export default function Error() {
  return (
    <Section lighten>
      <div className="mx-auto max-w-screen-sm text-center my-16">
        <h1 className="mb-4 text-7xl tracking-tight font-extrabold lg:text-9xl text-white">
          500
        </h1>
        <p className="mb-4 text-3xl tracking-tight font-bold text-white md:text-4xl">
          Internal Server Error.
        </p>
        <p className="mb-4 text-lg font-light text-gray-500 dark:text-gray-400">
          We are already working to solve the problem... probably.
        </p>
      </div>
    </Section>
  );
}